import { selector } from 'recoil';

import { formioData, iframeParams } from '@Recoil/formio/atoms';
import FormIoApi from '@Services/$formio-api';

const getSubmissions = selector({
  get: async ({ get }) => {
    try {
      const { reservationCode } = get(formioData);
      const { transactionHash, formIoId } = get(iframeParams);
      if (reservationCode || transactionHash) {
        const submission = await FormIoApi.getFormIoSubmissions(
          reservationCode,
          transactionHash,
          formIoId,
        );

        const {
          firstName,
          lastName,
          email,
          formSubmissionId,
        } = submission[0].owner;

        return {
          ...submission[0],
          owner: {
            email,
            firstName,
            lastName,
            submissionId: formSubmissionId,
          },
          submissions: submission[0].submission
            ? submission[0].submission.map((user) => ({
                email: user.submissions[0].data.email,
                firstName: user.submissions[0].data.firstname,
                lastName: user.submissions[0].data.lastname || '',
                submissionId: user.submissions[0].submissionId,
              }))
            : [],
        };
      }
    } catch {}
  },
  key: 'getSubmissions',
});

export default getSubmissions;
