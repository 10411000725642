import React, { useEffect, useState } from 'react';

import { Button, Grid, ListItem, Typography } from '@material-ui/core';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import config from '@Config';
import {
  formioData,
  iframeParams,
  ownerState,
  peopleState,
} from '@Recoil/formio/atoms';
import { getFormIoUser } from '@Recoil/formio/selectors';
import getSubmissions from '@Recoil/formio/selectors/getSubmissions';
import { translation } from '@Recoil/lang/selectors';
import Analytics from '@Services/$analytics-provider';
import useStyles from './AddBuyerStep.styles';
import { IAddBuyerStepProps } from './AddBuyerStep.types';

const AddBuyerStep = ({
  onBack,
  setStepByName,
  removeStepFromPrevSlugs,
}: IAddBuyerStepProps) => {
  const styles = useStyles();

  const { entryListL } = useRecoilValue(translation);
  const buyer = useRecoilValue(getSubmissions);
  const setPeople = useSetRecoilState(peopleState);
  const setFormioData = useSetRecoilState(formioData);
  const setOwner = useSetRecoilState(ownerState);
  const [ownerId, setOwnerId] = useState<string | null>(null);
  const { formIoId } = useRecoilValue(iframeParams);

  const owner = buyer && buyer.owner;

  const checkUserExist = useRecoilCallback(
    ({ snapshot }) => async (
      userName: string,
      userLastName: string,
      userEmail: string,
    ) => {
      const user = await snapshot.getPromise(
        getFormIoUser({ userName, userLastName, userEmail, formIoId }),
      );
      if (user && 'length' in user && user.length) {
        return user;
      }

      return [];
    },
  );

  const getOwnerSubmissionId = async () => {
    if (owner) {
      const user = await checkUserExist(owner.firstName,owner.lastName, owner.email);
      if (user[0]) {
        setOwnerId(user[0].submission[0].submissionId);
      }
    } else {
      setOwnerId(null);
    }
  };

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: entryListL.addBuyerStep.title,
    });
  }, []);

  useEffect(() => {
    getOwnerSubmissionId();
    if (buyer && buyer.submission && buyer.submission.length) {
      setStepByName('addNewPeople');
      removeStepFromPrevSlugs('addBuyer');
      setPeople(buyer.submissions);
    }
    if (!buyer) {
      setStepByName('ticketCode');
    } else {
      const peopleCount = String(buyer.entriesQuantity);

      setFormioData((oldState) => {
        return { ...oldState, peopleCount };
      });
    }
  }, []);

  const addBuyer = () => {
    if (owner) {
      setOwner({ ...owner, submissionId: ownerId });
      setStepByName('addPeople');
      if (ownerId) {
        setPeople([{ ...owner, submissionId: ownerId }]);
      }
    }
  };

  const withoutBuyer = () => {
    setPeople([]);
    setStepByName('addNewPeople');
  };

  const spacing = config.spacings;

  if (!owner) {
    return null;
  }

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={entryListL.addBuyerStep.title}
      withBottomAppendix={true}
      onBottomAppendixClick={onBack}
    >
      <Grid container={true} spacing={spacing.regular} direction="column">
        <Grid item={true} xs={12}>
          <ListItem>
            <div>
              <Typography variant="body1">{`${owner.firstName} ${owner.lastName}`}</Typography>
              <Typography variant="body2" color="textSecondary">
                {owner.email}
              </Typography>
            </div>
          </ListItem>
        </Grid>
        <Grid item={true}>
          <Grid container={true} spacing={spacing.regular}>
            <Grid item={true} xs={6}>
              <Button
                className={styles.button}
                size="large"
                variant="contained"
                color="primary"
                onClick={addBuyer}
              >
                {entryListL.yes}
              </Button>
            </Grid>
            <Grid item={true} xs={6}>
              <Button
                className={styles.button}
                size="large"
                variant="outlined"
                color="primary"
                onClick={withoutBuyer}
              >
                {entryListL.no}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeaderContentMUI>
  );
};

export default AddBuyerStep;
