import { IBookingTimeSlot } from '@Model/booking/types';
import { createSelector } from 'reselect';
import getTimeSlots from './getTimeSlots';

const prepareResultArray = (slots: IBookingTimeSlot[]) => {
  const spacesSlots: { [key: string]: IBookingTimeSlot[] } = {};
  slots[0].spaces.forEach((space) => {
    spacesSlots[space.spaceId] = [];
  });

  for (let i = 0; i < slots.length; i++) {
    slots[i].spaces.forEach((space) => {
      if (!space.reserved) {
        spacesSlots[space.spaceId].push(slots[i]);
      }
    });
  }

  const filteredSlots = Object.keys(spacesSlots)
    .map((key) => {
      let firstEnable = -1;
      for (let i = 0; i < spacesSlots[key].length; i++) {
        if (!spacesSlots[key][i].isDisabled) {
          firstEnable = i;

          break;
        }
      }
      return spacesSlots[key].slice(0, firstEnable + 1)[0];
    })
    .filter((value) => value)
    .filter(
      (value, index, array) =>
        array.findIndex((v) => v.start === value.start) === index,
    )
    .sort((a, b) => a.start.localeCompare(b.start));

  return filteredSlots;
};

const getGroupedTimeSlots = createSelector([getTimeSlots], (slots) => {
  let key = 'nil';
  const keys: string[] = [];
  const result: IBookingTimeSlot[][] = [];

  slots.forEach((_slot) => {
    const hour = _slot.start.substr(0, 2);

    if (hour !== key) {
      result.push([]);

      key = hour;
      keys.push(hour);
    }

    result[result.length - 1].push(_slot);
  });

  let remove = true;

  return result
    .map((_result, index) => ({
      hour: `${keys[index]}:00`,
      slots: prepareResultArray(_result),
    }))
    .filter((_result) => {
      if (_result.slots.length < 1) {
        return !remove;
      } else {
        remove = false;

        return true;
      }
    });
});

export default getGroupedTimeSlots;
