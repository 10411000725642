import React, { useEffect } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';
import { useRecoilValue } from 'recoil';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import { TypographyGoingJP } from '@/srcShared/ui/particles/TypographyGoingJP';
import { TypographyGoingJPVariant } from '@/srcShared/ui/particles/TypographyGoingJP/models/TypographyGoingJP';
import config from '@Config';
import { getEntryGroup } from '@Recoil/formio/selectors';
import { translation } from '@Recoil/lang/selectors';
import Analytics from '@Services/$analytics-provider';
import { IThanksStepProps } from './ThanksStep.types';

import useStyles from './ThanksStep.styles';

const ThanksStep = ({ setStepByName, saved }: IThanksStepProps) => {
  const styles = useStyles();

  const group = useRecoilValue(getEntryGroup);

  const code = group ? group.slug : undefined;
  const { entryListL } = useRecoilValue(translation);

  const spacing = config.spacings;

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: entryListL.thanksStep.thanks,
    });
  }, []);

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={saved ? entryListL.thanksStep.saved : entryListL.thanksStep.thanks}
    >
      <Grid container={true} spacing={spacing.regular} direction="column">
        <Grid item={true} xs={12}>
          <Typography variant="body2">
            {saved
              ? entryListL.thanksStep.savedDescription
              : entryListL.thanksStep.code}
          </Typography>
        </Grid>
        {!saved && config.app.showLastStepEntryListCode && (
          <Grid item={true} xs={12}>
            <TypographyGoingJP
              typographyVariant="h5"
              variant={TypographyGoingJPVariant.yellowUnderline}
            >
              {code || ''}
            </TypographyGoingJP>
          </Grid>
        )}
        <Grid item={true}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Button
                className={styles.button}
                size="large"
                variant="contained"
                color="primary"
                onClick={() =>
                  setStepByName(
                    config.app.showFirstStepEntryList
                      ? 'haveTicketCode'
                      : 'ticketCode',
                  )
                }
              >
                {entryListL.thanksStep.back}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeaderContentMUI>
  );
};

export default ThanksStep;
