import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  DialogActions,
  Grid,
  Snackbar,
  Typography,
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { Alert, AlertTitle } from '@material-ui/lab';
import cn from 'classnames';
import moment from 'moment';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import uuid from 'uuid';

import DatePickerModal from '@Compo/CustomByClient/Hangar/Components/ConfigurationModal/DatePickerModal.component';
import config from '@Config';
import {
  formioData,
  iframeParams,
  ownerState,
  peopleState,
  personEmail,
  successMessageState,
} from '@Recoil/formio/atoms';
import {
  getFormIoUser,
  getSaveUserData,
  getSubmissionsAttach,
} from '@Recoil/formio/selectors';
import { translation } from '@Recoil/lang/selectors';
import { IFormioRedirectProps } from '@Services/$formio-api/types';
import { DEFAULT_PHONE_NUMBER, sendSms } from './EntryTerm.helpers';
import {
  IEntryTermProps,
  IFormIoChange,
  IFormIoCustomEvent,
  IFormIoNextPage,
  IFormIoPrevPage,
  IPage2Submission,
  ISubmission,
} from './EntryTerm.types';

import useStyles from './EntryTerm.styles';
import './styles/Bootstrap.module.scss';
import './styles/FormioNative.module.scss';
import FormioOverride from './styles/FormioOverride.styles';

const EntryTerm = ({
  closeModal,
  openAlert,
  openAddPersonAlert,
  setOpenEmailModal,
}: IEntryTermProps) => {
  const { reservationCode } = useRecoilValue(formioData);
  const { entryListL } = useRecoilValue(translation);
  const setPersonEmail = useSetRecoilState(personEmail);
  const setSuccessMessage = useSetRecoilState(successMessageState);
  const { transactionHash, formIoId, entryListUrl } = useRecoilValue(
    iframeParams,
  );

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState<IPage2Submission | undefined>();
  const [formio, setFormio] = useState<any>();
  const [showDatePicker, handleShowDatePicker] = useState(false);
  const formRef = useRef(null);
  const [submission, setSubmission] = useState<ISubmission>({
    data: {
      page1Kodrezerwacji: reservationCode,
    },
  } as ISubmission);
  const [page, setPage] = useState<number>(0);
  const [redirected, setRedirected] = useState<boolean>(false);
  const [checkedUser, setCheckedUser] = useState<boolean>(false);
  const [smsSended, setSmsSended] = useState<boolean>(false);
  const [filteredInputs, setFilteredInputs] = useState<HTMLInputElement[]>([]);
  const [iframeQueue, setIframeQueue] = useState<
    Array<{ key: string; value: string }>
  >([]);

  const [alert, setAlert] = useState<{ title?: string; description?: string }>(
    {},
  );

  const [people, setPeople] = useRecoilState(peopleState);
  const saveUserSubmission = useRecoilCallback(
    ({ snapshot }) => (userData: any) => {
      return snapshot.getPromise(getSaveUserData(userData));
    },
  );

  const saveAttachmentSubmission = useRecoilCallback(
    ({ snapshot }) => (submissionId: string) => {
      return snapshot.getPromise(
        getSubmissionsAttach({ submissionId, id: String(Date.now()) }),
      );
    },
  );

  const [owner, setOwner] = useRecoilState(ownerState);

  const showContainedButtons = config.theme.isSaltos || config.theme.isHangar;

  const showShareButton = page === 1 && reservationCode;

  const catchSaveUser = (userData: { data: IPage2Submission; url: string }) => {
    const { data } = userData;
    if (userData.data.data.autocomplete !== 'true') {
      saveUserSubmission(userData);
    }

    if (reservationCode || transactionHash) {
      setTimeout(() => {
        if (data._id) {
          saveAttachmentSubmission(data._id).then((response) => {
            if (response) {
              setPeople((oldState) => {
                return [
                  ...oldState,
                  {
                    email: data.data.email,
                    firstName: data.data.firstname,
                    lastName: data.data.lastname,
                    submissionId: data._id,
                  },
                ];
              });
              closeModal();
              setError(false);
              setErrorData(undefined);
            } else {
              setOpen(true);
              setError(true);
              setErrorData(data);
            }
          });
        }
      }, 1000);
    } else {
      setPeople((oldState) => {
        return [
          ...oldState,
          {
            email: data.data.email,
            firstName: data.data.firstname,
            lastName: data.data.lastname,
            submissionId: data._id,
          },
        ];
      });
      closeModal();
      setError(false);
      setErrorData(undefined);
    }
  };

  useEffect(() => {
    if (!owner.submissionId) {
      setSubmission({
        data: {
          ...submission.data,
          email: owner.email,
          firstname: owner.firstName,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (reservationCode) {
      setSubmission({
        data: {
          entryToken: reservationCode,
        },
      });
    } else {
      setSubmission({} as ISubmission);
    }
  }, []);

  const onChange = (data: IFormIoChange) => {
    const { autoRedirectFormPath, autocomplete, page2Enterform } = data.data;

    if (autocomplete === 'true') {
      onNextClick();
    }

    if (page2Enterform && page2Enterform.data.autocomplete === 'true') {
      onSubmitClick();
    }

    if (autoRedirectFormPath && autoRedirectFormPath.length && !redirected) {
      setRedirected(true);
    }
  };

  const tryBuildIframe = (domElementName: string, iframeValue: string) => {
    const pdfUrl = `https://docs.google.com/gview?url=${iframeValue}&embedded=true`;
    if (domElementName.startsWith('iframe')) {
      addToIframeQueue(domElementName, iframeValue);

      const elements = document.getElementsByName(`data[${domElementName}]`);

      if (elements && elements.length) {
        const element = elements[0];

        const iframe = document.createElement('div');

        const odlIframe = document.getElementById(domElementName);

        if (odlIframe) {
          odlIframe.remove();
        }

        iframe.style.cssText = 'display: flex; justify-content: center;';

        const ifrm = document.createElement('iframe');
        ifrm.setAttribute('src', pdfUrl);
        ifrm.setAttribute('width', '100%');
        ifrm.setAttribute('id', domElementName);
        ifrm.setAttribute('height', '500px');
        ifrm.setAttribute('scrolling', 'no');
        ifrm.setAttribute('frameborder', '0');

        ifrm.onload = () => {
          removeFromIframeQueue(domElementName);
        };

        iframe.innerHTML = '';
        iframe.appendChild(ifrm);

        const parent = element.parentNode;

        if (parent) {
          parent.insertBefore(iframe, element);
        }
      }
    }
  };

  const checkIframeElement = (data: ISubmission) => {
    const keys = Object.keys(data.data) as Array<keyof typeof data.data>;
    keys.forEach((key) => {
      const value = data.data[key];

      if (typeof value === 'string') {
        tryBuildIframe(key, value);
      } else if (
        key === 'page2Enterform' &&
        value &&
        typeof value !== 'number' &&
        value.data
      ) {
        const keysSecond = Object.keys(value.data) as Array<
          keyof typeof value.data
        >;

        keysSecond.forEach((keySecond) => {
          const valueSecond = value.data[keySecond];

          if (valueSecond) {
            tryBuildIframe(keySecond, valueSecond);
          }
        });
      }
    });
  };

  useEffect(() => {
    return () => {
      filteredInputs.forEach((input) =>
        input.removeEventListener('keypress', function(e) {
          keyPress(this, e);
        }),
      );
    };
  }, []);

  const checkUserExist = useRecoilCallback(
    ({ snapshot }) => async (
      userName: string,
      userLastName: string,
      userEmail: string,
    ) => {
      const user = await snapshot.getPromise(
        getFormIoUser({ userName, userEmail, userLastName, formIoId }),
      );

      if (user && 'length' in user && user.length) {
        return user;
      } else if (user && 'data' in user) {
        setSubmission((oldState) => {
          return { ...oldState, ...user };
        });
      }

      return [];
    },
  );

  const checkIfUserOnList = (submissionId: string) =>
    people.some((person) => person.submissionId === submissionId);

  const checkUserOnFirsPage = async (data: IFormIoNextPage) => {
    const { email, firstname, lastname } = data.submission.data;

    if (
      email &&
      email.length &&
      firstname &&
      firstname.length &&
      lastname &&
      lastname.length &&
      !checkedUser
    ) {
      setCheckedUser(true);
      const user = await checkUserExist(firstname, lastname, email);
      if (user.length) {
        if (!checkIfUserOnList(user[0].submission[0].submissionId)) {
          if (reservationCode || transactionHash) {
            saveAttachmentSubmission(user[0].submission[0].submissionId);
          }
          setPeople((oldState) => {
            if (
              oldState.every(
                (person) =>
                  user[0].submission[0].submissionId !== person.submissionId,
              )
            ) {
              if (user[0].submission[0].data.page2enterform) {
                return [
                  ...oldState,
                  {
                    email: user[0].submission[0].data.page2enterform.data.email,
                    firstName:
                      user[0].submission[0].data.page2enterform.data.firstname,
                    lastName:
                      user[0].submission[0].data.page2enterform.data.lastname,
                    submissionId: user[0].submission[0].submissionId,
                  },
                ];
              }

              return oldState;
            }

            return oldState;
          });
        } else {
          openAlert(true);
        }
        closeModal();
        setSuccessMessage({
          message:
            entryListL.addNewPeopleToTicketStep
              .thanksDescWhenPersonIsInDatabase,
          title: entryListL.addNewPeopleToTicketStep.thanks,
        });
      }
    }
  };

  const onNextPage = (data: IFormIoNextPage) => {
    setPage(data.page);

    const {
      page: currentPage,
      submission: {
        data: {
          page4Numbergen,
          page1SmsGateWay,
          page1SmsTekst,
          page1EnterFormFormUrl,
          page1RedirectAfterSubmit,
          page2Enterform,
          page3phone,
          page3parentfirstname,
          page3parentlastname,
          firstname,
          lastname,
          email,
          entryToken,
          userFormUrl,
          nextPage,
          autoRedirectFormPath,
          iframe,
        },
      },
    } = data;
    checkIsAlert(data);

    if (currentPage === 1) {
      checkUserOnFirsPage(data);
      setPersonEmail(
        email ? { id: uuid.v4(), value: email, error: false } : null,
      );
      setSubmission({
        data: {
          ...data.submission.data,
          entryToken: reservationCode || undefined,
          page2Enterform: {
            data: {
              ...data.submission.data.page2Enterform,
              email,
              firstname,
              lastname,
            },
          },
        },
      });
    } else if (
      data &&
      currentPage === 3 &&
      page4Numbergen &&
      page1SmsGateWay &&
      page1SmsTekst &&
      page3phone &&
      !smsSended
    ) {
      setSmsSended(true);
      setTimeout(() => {
        sendSms(page1SmsGateWay, page3phone, page4Numbergen, page1SmsTekst);
      }, 300);
    } else if (
      data.page === 4 &&
      (page3parentfirstname === '' ||
        (page3parentfirstname && !page3parentfirstname.length) ||
        page3parentfirstname === '-')
    ) {
      if (page2Enterform) {
        setSubmission({
          data: {
            ...data.submission.data,
            form: {
              data: {
                autoRedirectFormPath,
                iframe,
                nextPage,
                page1Email: email,
                page1EnterFormFormUrl,
                page1Imi: firstname,
                page1Kodrezerwacji: reservationCode || undefined,
                page1RedirectAfterSubmit,
                page1SmsGateWay,
                page1SmsTekst,
                page2Enterform: {
                  data: {
                    ...page2Enterform.data,
                    data: {
                      ...page2Enterform.data.data,
                      dataurodzenia: page2Enterform.data.dataurodzenia,
                      imie: firstname,
                      nazwisko: page2Enterform.data.lastname,
                    },
                    dataurodzenia: page2Enterform.data.dataurodzenia,
                    imie: firstname,
                    nazwisko: page2Enterform.data.lastname,
                  },
                },
                page3parentfirstname: page3parentfirstname || '-',
                page3parentlastname: page3parentlastname || '-',
                page3phone: page3phone || DEFAULT_PHONE_NUMBER,
                page4Numbergen,
                userFormUrl,
              },
            },
            lastname: page2Enterform.data.lastname,
            page3parentfirstname: page3parentfirstname || '-',
            page3parentlastname: page3parentlastname || '-',
            page3phone: page3phone || DEFAULT_PHONE_NUMBER,
          },
        });
      }
    } else if (data.page === 4 && page3parentfirstname !== '-') {
      if (page2Enterform) {
        setSubmission({
          data: {
            ...data.submission.data,
            form: {
              data: {
                autoRedirectFormPath,
                iframe,
                nextPage,
                page1Email: email,
                page1EnterFormFormUrl,
                page1Imi: firstname,
                page1Kodrezerwacji: reservationCode || undefined,
                page1RedirectAfterSubmit,
                page1SmsGateWay,
                page1SmsTekst,
                page2Enterform: {
                  data: {
                    ...page2Enterform.data,
                    data: {
                      ...page2Enterform.data.data,
                      dataurodzenia: page2Enterform.data.dataurodzenia,
                      imie: firstname,
                      nazwisko: page2Enterform.data.lastname || lastname,
                    },
                    dataurodzenia: page2Enterform.data.dataurodzenia,
                    imie: firstname,
                    nazwisko: page2Enterform.data.lastname,
                  },
                },
                page3parentfirstname: page3parentfirstname || '-',
                page3parentlastname: page3parentlastname || '-',
                page3phone: page3phone || DEFAULT_PHONE_NUMBER,
                page4Numbergen,
                userFormUrl,
              },
            },
            page3parentfirstname: page3parentfirstname || '-',
            page3parentlastname: page3parentlastname || '-',
            page3phone: page3phone || DEFAULT_PHONE_NUMBER,
          },
        });
      }
    }
  };

  const checkIsAlert = (data: IFormIoPrevPage | IFormIoNextPage) => {
    const {
      page: currentPage,
      submission: {
        data: {
          page2alertDescription,
          page2alertTitle,
          page3alertDescription,
          page3alertTitle,
          page4alertDescription,
          page4alertTitle,
        },
      },
    } = data;
    switch (currentPage) {
      case 1:
        return setAlert({
          description: page2alertDescription,
          title: page2alertTitle,
        });
      case 2:
        return setAlert({
          description: page3alertDescription,
          title: page3alertTitle,
        });
      case 3:
        return setAlert({
          description: page4alertDescription,
          title: page4alertTitle,
        });
      default:
        return setAlert({});
    }
  };

  const onPrevPage = (data: IFormIoPrevPage) => {
    setPage(data.page);
    checkIsAlert(data);
  };

  const onSubmitDone = (data: ISubmission) => {
    if (data.data.page2Enterform && data.data.userFormUrl) {
      catchSaveUser({
        data: {
          _id: data._id,
          data: {
            ...data.data.page2Enterform.data,
            dataurodzenia2: moment(
              data.data.page2Enterform.data.dataurodzenia,
            ).format('YYYY-MM-DD'),
            imie: data.data.page2Enterform.data.firstname,
            nazwisko: data.data.page2Enterform.data.lastname,
          },
        },
        url: data.data.userFormUrl,
      });
      if (!owner.submissionId) {
        setOwner((oldOwner) => ({ ...oldOwner, submissionId: data._id }));
      }

      openAddPersonAlert(true);
      setSuccessMessage({
        message: entryListL.addNewPeopleToTicketStep.thanksDescription,
        title: entryListL.addNewPeopleToTicketStep.thanks,
      });
    }
  };

  const onCustomEvent = (data: IFormIoCustomEvent) => {
    if (data.type === 'smsResend') {
      if (
        data &&
        data.data.page4Numbergen &&
        data.data.page1SmsGateWay &&
        data.data.page1SmsTekst &&
        data.data.page3phone
      ) {
        sendSms(
          data.data.page1SmsGateWay,
          data.data.page3phone,
          data.data.page4Numbergen,
          data.data.page1SmsTekst,
        );
      }
    }
  };

  const keyPress = (that: HTMLInputElement, e: KeyboardEvent) => {
    if (e.which === 13) {
      e.preventDefault();
      const nextInput = document.querySelectorAll(
        `[tabIndex="${that.tabIndex + 1}"]`,
      );
      if (nextInput.length === 0) {
        const button = document.getElementsByClassName(
          'btn-wizard-nav-next',
        )[0];
        if (button instanceof HTMLButtonElement) {
          button.click();
        }
      }
      if (nextInput[0] && nextInput[0] instanceof HTMLInputElement) {
        const input = nextInput[0] as HTMLInputElement;
        input.focus();
      }
    }
  };

  const onNextClick = () => {
    const nextButton = document.getElementsByClassName(
      'btn-wizard-nav-next',
    ) as HTMLCollectionOf<HTMLButtonElement>;
    if (nextButton[0]) {
      nextButton[0].click();
    }
  };

  const onBackClick = () => {
    const backButton = document.getElementsByClassName(
      'btn-wizard-nav-previous',
    ) as HTMLCollectionOf<HTMLButtonElement>;
    if (page === 0) {
      closeModal();
    } else if (backButton[0]) {
      backButton[0].click();
    }
  };

  const onSubmitClick = () => {
    const submitButton = document.getElementsByClassName(
      'btn-wizard-nav-submit',
    ) as HTMLCollectionOf<HTMLButtonElement>;

    if (submitButton[0]) {
      submitButton[0].click();
    }
  };

  const onRetryClick = () => {
    if (error && errorData && errorData._id) {
      saveAttachmentSubmission(errorData._id).then((response) => {
        if (response) {
          setPeople((oldState) => {
            return [
              ...oldState,
              {
                email: errorData.data.email,
                firstName: errorData.data.firstname,
                lastName: errorData.data.lastname,
                submissionId: errorData._id,
              },
            ];
          });
          closeModal();
          setError(false);
          setErrorData(undefined);
        } else {
          setOpen(true);
          setError(true);
          setErrorData(errorData);
        }
      });
    }
  };

  const onRender = (data: any) => {
    tryAddEventListenerForDatePicker(data);
    if (data && data.component && data.component._data) {
      checkIframeElement({ data: data.component._data });
    }

    setCheckedUser(false);
    const inputs = document.getElementsByClassName(
      'form-control',
    ) as HTMLCollectionOf<HTMLInputElement>;
    const elements = Array.from(inputs);
    const filteredInput = elements.filter((el) => el.hasAttribute('tabindex'));
    setFilteredInputs(filteredInput);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < filteredInput.length; i++) {
      filteredInput[i].addEventListener('keypress', function(e) {
        keyPress(this, e);
      });
    }
  };

  const addToIframeQueue = (key: string, value: string) => {
    setIframeQueue((oldState) => {
      if (oldState.find((iframe) => iframe.key === key)) {
        return oldState;
      }

      return [...oldState, { key, value }];
    });
  };

  const removeFromIframeQueue = (key: string) => {
    setIframeQueue((oldState) => {
      const newQueue = oldState.filter((iframe) => {
        return iframe.key !== key;
      });

      return newQueue;
    });
  };

  const runIframeQueue = () => {
    setIframeQueue((oldState) => {
      if (oldState.length) {
        oldState.forEach(({ key, value }) => {
          tryBuildIframe(key, value);
        });
      }

      return oldState;
    });
  };

  useEffect(() => {
    if (iframeQueue && iframeQueue.length) {
      const interval = setInterval(() => {
        runIframeQueue();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [iframeQueue]);

  const styles = useStyles();

  const onFormLoad = () => {
    if (!owner.submissionId) {
      setSubmission({
        data: {
          ...submission.data,
          email: owner.email,
          firstname: owner.firstName,
        },
      });
    }
  };

  useEffect(() => {
    if (formRef) {
      try {
        window.Formio.createForm(
          formRef.current,
          entryListUrl || config.app.entryListUrl,
        ).then((form: IFormioRedirectProps) => {
          form.submission = submission;

          setFormio(form);

          form.on('submitDone', (data) => {
            onSubmitDone(data);
          });

          form.on('change', (data) => onChange(data));

          form.on('render', (data) => onRender(data));

          form.on('nextPage', (data) => onNextPage(data));

          form.on('prevPage', (data) => onPrevPage(data));

          form.on('customEvent', (data) => onCustomEvent(data));

          form.on('initialized', () => onFormLoad());
        });
      } catch (noSSR) {}
    }
  }, [formRef]);

  useEffect(() => {
    if (formio) {
      formio.submission = { ...submission };
    }
  }, [submission]);

  const tryAddEventListenerForDatePicker = (data: any) => {
    const elementList = document.getElementsByClassName(
      'formio-component-dataurodzenia2 ',
    );

    if (elementList && elementList[0]) {
      setSubmission({ data: data.component._data });

      elementList[0].addEventListener('click', () => {
        handleShowDatePicker(!showDatePicker);
      });
    }
  };

  const handleData = (date: Date) => {
    handleShowDatePicker(false);

    setSubmission((oldState) => {
      const data = {
        ...oldState.data,
        page2Enterform: {
          data: {
            ...(oldState && oldState.data && oldState.data.page2Enterform
              ? oldState.data.page2Enterform.data
              : {}),
            dataurodzenia: moment(date).format('YYYY-MM-DD'),
            dataurodzenia2: moment(date).format('YYYY-MM-DD'),
          },
        },
      };
      return {
        ...oldState,
        data,
      };
    });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {showDatePicker && !entryListUrl && (
        <DatePickerModal onChange={handleData} />
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          {entryListL.entryTerm.error}
        </Alert>
      </Snackbar>
      <div id="formio" className={styles.formio}>
        {alert.title && alert.description && (
          <Alert severity="info" variant="filled" className={styles.alert}>
            <AlertTitle>{alert.title}</AlertTitle>
            {alert.description.split('<br>').map((text, index) => (
              <Typography key={index} variant="body2" component="p">
                {text}
              </Typography>
            ))}
          </Alert>
        )}
        <div ref={formRef} />
      </div>

      <DialogActions
        className={cn(showContainedButtons && styles.buttonsContainer)}
      >
        {showContainedButtons && (
          <Grid container={true} spacing={6}>
            {showShareButton && (
              <Grid item={true} xs={true}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenEmailModal(true)}
                  fullWidth={true}
                  startIcon={<MailIcon />}
                >
                  {entryListL.entryTerm.sendEmail}
                </Button>
              </Grid>
            )}
            <Grid container={true} item={true} xs={true} spacing={1}>
              <Grid item={true} xs={true}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onBackClick}
                  fullWidth={true}
                >
                  {entryListL.back}
                </Button>
              </Grid>
              {page !== 4 && (
                <Grid item={true} xs={true}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onNextClick}
                    fullWidth={true}
                  >
                    {entryListL.next}
                  </Button>
                </Grid>
              )}
              {page === 4 && (
                <>
                  {error ? (
                    <Grid item={true} xs={true}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onRetryClick}
                        fullWidth={true}
                      >
                        {entryListL.entryTerm.retry}
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item={true} xs={true}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmitClick}
                        fullWidth={true}
                      >
                        {entryListL.entryTerm.confirm}
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}
        {!showContainedButtons && (
          <>
            {showShareButton && (
              <Button
                variant="text"
                color="primary"
                onClick={() => setOpenEmailModal(true)}
                startIcon={<MailIcon />}
              >
                {entryListL.entryTerm.sendEmail}
              </Button>
            )}

            <Button variant="text" color="primary" onClick={onBackClick}>
              {entryListL.back}
            </Button>

            {page !== 4 && (
              <Button variant="text" color="primary" onClick={onNextClick}>
                {entryListL.next}
              </Button>
            )}
            {page === 4 && (
              <>
                {error ? (
                  <Button variant="text" color="primary" onClick={onRetryClick}>
                    {entryListL.entryTerm.retry}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={onSubmitClick}
                  >
                    {entryListL.entryTerm.confirm}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </DialogActions>
      <FormioOverride />
    </>
  );
};

export default EntryTerm;
