import { selector } from 'recoil';

import { formioData, peopleState } from '@Recoil/formio/atoms';
import FormIoApi from '@Services/$formio-api';

const getEntryGroup = selector({
  get: async ({ get }) => {
    try {
      const { peopleCount } = get(formioData);
      const people = get(peopleState);
      const submissionIds: string[] = [];

      people.forEach((person) => {
        if (person.submissionId) {
          submissionIds.push(person.submissionId);
        }
      });

      const code = await FormIoApi.getEntryGroup(
        Number(peopleCount),
        submissionIds,
      );

      return code;
    } catch {}
  },
  key: 'getEntryGroup',
});

export default getEntryGroup;
