import AvailabilitiesApi from '@Services/$availabilities-api';
import BookingApi from '@Services/$booking-api';
import DeviceDetector from '@Services/$device-detector';
import ExtendedPaymentApi from '@Services/$extendedPayment-api';
import FormIoApi from '@Services/$formio-api';
import HappeningsApi from '@Services/$happenings-api';
import IframeProvider from '@Services/$iframe-provider';
import LinksProvider from '@Services/$links-provider';
import PriceApi from '@Services/$price-api';
import ProductsApi from '@Services/$products-api';
import UpSellApi from '@Services/$upSell-api';

export interface IServices {
  availabilitiesApi: typeof AvailabilitiesApi;
  bookingApi: typeof BookingApi;
  deviceDetector: typeof DeviceDetector;
  happeningsApi: typeof HappeningsApi;
  extendedPaymentApi: typeof ExtendedPaymentApi;
  productsApi: typeof ProductsApi;
  iframeProvider: typeof IframeProvider;
  linksProvider: typeof LinksProvider;
  priceAPi: typeof PriceApi;
  upSellApi: typeof UpSellApi;
  formIoApi: typeof FormIoApi;
}

const services: IServices = {
  availabilitiesApi: AvailabilitiesApi,
  bookingApi: BookingApi,
  deviceDetector: DeviceDetector,
  extendedPaymentApi: ExtendedPaymentApi,
  formIoApi: FormIoApi,
  happeningsApi: HappeningsApi,
  iframeProvider: IframeProvider,
  linksProvider: LinksProvider,
  priceAPi: PriceApi,
  productsApi: ProductsApi,
  upSellApi: UpSellApi,
};

export default services;
