import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  ListItem,
  Typography,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import EmailModal from '@Compo/HappeningRegister/RegisterNewPersonModal/EntryTerm/EmailModal';
import config from '@Config';
import {
  formioData,
  iframeParams,
  ownerState,
  peopleState,
  personEmail,
  successMessageState,
} from '@Recoil/formio/atoms';
import {
  deleteSubmission,
  getSubmissionsAttach,
} from '@Recoil/formio/selectors';
import { translation } from '@Recoil/lang/selectors';
import Analytics from '@Services/$analytics-provider';
import RegisterNewPersonModal from '../RegisterNewPersonModal';
import useStyles from './AddNewPeopleToTicketStep.styles';
import { IAddNewPeopleToTicketStepProps } from './AddNewPeopleToTicketStep.types';

const AddNewPeopleToTicketStep = ({
  onBack,
  setStepByName,
  openAddPersonAlert,
  openAlert,
  setSaved,
}: IAddNewPeopleToTicketStepProps) => {
  const styles = useStyles();
  const [people, setPeople] = useRecoilState(peopleState);
  const { reservationCode: ticketCode, peopleCount } = useRecoilValue(
    formioData,
  );
  const { transactionHash } = useRecoilValue(iframeParams);
  const { entryListL } = useRecoilValue(translation);
  const owner = useRecoilValue(ownerState);
  const { reservationCode } = useRecoilValue(formioData);
  const setEmailPerson = useSetRecoilState(personEmail);

  const [successMessage, setSuccessMessage] = useRecoilState(
    successMessageState,
  );

  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);

  const saveAttachPeople = useCallback(async () => {
    await Promise.all(
      people.map(async (person) => {
        if (person.submissionId) {
          await saveAttachmentSubmission(person.submissionId);
        }
      }),
    );
  }, []);

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: 'Dodawanie nowych osób',
    });
  }, []);

  useEffect(() => {
    if (Object.keys(owner).length !== 0 && !owner.submissionId) {
      setOpenRegisterModal(true);
    }
    saveAttachPeople();
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => setSuccessMessage(null), 3000);
    }
  }, [successMessage]);

  const checkPeopleCount = () => {
    return people.length === Number(peopleCount);
  };

  const closeModal = () => setOpenRegisterModal(false);

  const saveAttachmentSubmission = useRecoilCallback(
    ({ snapshot }) => (submissionId: string) => {
      return snapshot.getPromise(
        getSubmissionsAttach({ submissionId, id: String(Date.now()) }),
      );
    },
  );

  const detachUserSubmission = useRecoilCallback(
    ({ snapshot }) => (data: { submissionId: string; timestamp: number }) => {
      return snapshot.getPromise(deleteSubmission(data));
    },
  );

  const addPerson = async () => {
    if (!checkPeopleCount()) {
      setOpenRegisterModal(true);
      Analytics.logEvent('select_item', {
        item_list_name: entryListL.addNewPeopleToTicketStep.addNewPerson,
      });
    } else {
      await Promise.all(
        people.map(async (person) => {
          if (person.submissionId) {
            await saveAttachmentSubmission(person.submissionId);
          }
        }),
      );
      setSaved(false);
      setStepByName('thanks');
    }
  };
  const removePerson = async (id: number) => {
    const person = people.find((data, index) => index === id);
    if (person && person.submissionId) {
      const array = people.filter((data, index: number) => index !== id);

      detachUserSubmission({
        submissionId: person.submissionId,
        timestamp: Date.now(),
      }).then(() => setPeople(array));
    } else if (person && person.submissionId && !reservationCode) {
      const array = people.filter((data, index: number) => index !== id);
      setPeople(array);
    }
  };

  const spacing = config.spacings;

  const sendEmail = () => {
    setOpenEmailModal(true);
    Analytics.logEvent('select_item', {
      item_list_name: entryListL.entryTerm.sendEmail,
    });
  };

  const closeEmailModal = () => {
    setOpenEmailModal(false);
    setEmailPerson(null);
  };

  const saveAndCompleteLater = () => {
    setSaved(true);
    setStepByName('thanks');
    Analytics.logEvent('select_item', {
      item_list_name: entryListL.entryTerm.saveAndCompleteLater,
    });
  };

  const isCanBeDone = checkPeopleCount();
  const isAdditionalSpace = config.theme.isSaltos;
  const hasTicketCodeOrTransactionHash = ticketCode || transactionHash;

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={
        isCanBeDone
          ? entryListL.addNewPeopleToTicketStep.checkData
          : `${entryListL.addNewPeopleToTicketStep.title} ${Number(
              peopleCount,
            ) - people.length} osób`
      }
      withBottomAppendix={true}
      onBottomAppendixClick={onBack}
    >
      <RegisterNewPersonModal
        openAlert={openAlert}
        openAddPersonAlert={openAddPersonAlert}
        open={openRegisterModal}
        close={closeModal}
        setOpenEmailModal={setOpenEmailModal}
      />
      <Dialog
        open={openEmailModal}
        maxWidth="sm"
        fullWidth={true}
        onClose={closeEmailModal}
        scroll="paper"
        classes={{ paper: styles.dialog }}
      >
        <EmailModal close={closeEmailModal} />
      </Dialog>
      <Dialog
        open={!!successMessage}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setSuccessMessage(null)}
        scroll="paper"
        classes={{ paper: styles.dialog }}
      >
        <DialogContent>
          <HeaderContentMUI
            withHeader={true}
            variant={HeaderContentMUIVariant.DESCRIPTION}
            title={successMessage ? successMessage.title : ''}
            text={[successMessage ? successMessage.message : '']}
            className={styles.headerContent}
          />
        </DialogContent>
      </Dialog>
      <Grid container={true} spacing={spacing.regular} direction="column">
        <Grid item={true} xs={12}>
          <Typography variant="body1">
            {hasTicketCodeOrTransactionHash
              ? entryListL.addNewPeopleToTicketStep.description
              : entryListL.addNewPeopleToTicketStep
                  .descriptionWithoutTicketCode}
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          {people.map((person, index) => (
            <ListItem key={index} className={styles.listItem}>
              <div>
                <Typography variant="body1">{`${person.firstName} ${person.lastName}`}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {person.email}
                </Typography>
              </div>
              <IconButton size="medium" onClick={() => removePerson(index)}>
                <DeleteOutlineIcon />
              </IconButton>
            </ListItem>
          ))}
        </Grid>
        <Grid item={true}>
          <Grid container={true} spacing={2}>
            <Grid
              container={true}
              spacing={1}
              item={true}
              direction={isAdditionalSpace ? 'row-reverse' : 'row'}
            >
              {!isCanBeDone && hasTicketCodeOrTransactionHash && (
                <Grid item={true} xs={6}>
                  <Button
                    variant={isAdditionalSpace ? 'contained' : 'outlined'}
                    color="primary"
                    size="large"
                    className={styles.button}
                    onClick={sendEmail}
                  >
                    {entryListL.entryTerm.sendEmail}
                  </Button>
                </Grid>
              )}
              <Grid
                item={true}
                xs={isCanBeDone || !hasTicketCodeOrTransactionHash ? 12 : 6}
              >
                <Button
                  className={styles.button}
                  size="large"
                  variant={
                    isAdditionalSpace &&
                    !isCanBeDone &&
                    (ticketCode || transactionHash)
                      ? 'outlined'
                      : 'contained'
                  }
                  color="primary"
                  onClick={addPerson}
                >
                  {isCanBeDone
                    ? entryListL.addNewPeopleToTicketStep.confirm
                    : entryListL.addNewPeopleToTicketStep.addNewPerson}
                </Button>
              </Grid>
            </Grid>
            {people.length > 0 &&
              !isCanBeDone &&
              hasTicketCodeOrTransactionHash && (
                <Grid item={true} xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={styles.button}
                    onClick={saveAndCompleteLater}
                  >
                    {entryListL.entryTerm.saveAndCompleteLater}
                  </Button>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </HeaderContentMUI>
  );
};

export default AddNewPeopleToTicketStep;
