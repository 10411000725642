import React, { useState } from 'react';

import { Grid, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSetRecoilState } from 'recoil';

import config from '@Config';
import {
  formioData as formioDataState,
  peopleState,
} from '@Recoil/formio/atoms';
import AddBuyerStep from './AddBuyerStep';
import AddNewPeopleToTicketStep from './AddNewPeopleToTicketStep';
import AddPeopleToTicketStep from './AddPeopleToTicketStep';
import useStyles from './HappeningRegister.styles';
import {
  IAvailableSteps,
  IHappeningRegisterStep,
} from './HappeningRegister.types';
import HaveTicketCodeStep from './HaveTicketCodeStep';
import NumberOfPeopleStep from './NumberOfPeopleStep';
import ThanksStep from './ThanksStep';
import TicketCodeStep from './TicketCodeStep';

const DEFAULT_STEP = config.app.showFirstStepEntryList ? 0 : 1;
const SAME_PERSON_IS_ON_LIST_TEXT = 'Ta osoba jest już na twojej liście!';
const ADDED_PERSON_TEXT = 'Dziękujemy, formularz został wysłany!';

const HappeningRegister = () => {
  const [activeStep, setActiveStep] = useState(DEFAULT_STEP);
  const [prevSlugs, setPrevSlugs] = useState<IAvailableSteps[]>([]);
  const [open, setOpen] = useState(false);
  const [openPersonAdded, setOpenPersonAdd] = useState(false);
  const [saved, setSaved] = useState(false);

  const setPeople = useSetRecoilState(peopleState);
  const setFormioData = useSetRecoilState(formioDataState);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenPersonAdd(false);
    setOpen(false);
  };

  const getStepByName = (stepName: IAvailableSteps): number => {
    return availableSteps.findIndex((step) => step.stepName === stepName);
  };

  const setStepByName = (stepName: IAvailableSteps) => {
    const step = getStepByName(stepName);
    if (step !== -1) {
      if (step !== DEFAULT_STEP) {
        setPrevSlugs([...prevSlugs, availableSteps[activeStep].stepName]);
      } else {
        setPrevSlugs([]);
        setPeople([]);
        setFormioData(() => {
          return { peopleCount: '', reservationCode: null };
        });
      }
      setActiveStep(step);
    }
  };

  const removeStepFromPrevSlugs = (stepName: IAvailableSteps) => {
    setPrevSlugs([...prevSlugs.filter((slug) => slug !== stepName)]);
  };

  const onBackClick = () => {
    const step = getStepByName(prevSlugs[prevSlugs.length - 1]);
    if (
      prevSlugs.length > 0 &&
      prevSlugs[prevSlugs.length - 1] !== availableSteps[DEFAULT_STEP].stepName
    ) {
      setActiveStep(step);
      setPrevSlugs(prevSlugs.slice(0, -1));
    } else {
      setActiveStep(DEFAULT_STEP);
      setPrevSlugs([]);
      setPeople([]);
      setFormioData((oldState) => ({
        ...oldState,
        peopleCount: '',
      }));
    }
  };

  const availableSteps: IHappeningRegisterStep[] = [
    {
      render: <HaveTicketCodeStep setStepByName={setStepByName} />,
      stepName: 'haveTicketCode',
    },
    {
      render: (
        <TicketCodeStep onBack={onBackClick} setStepByName={setStepByName} />
      ),
      stepName: 'ticketCode',
    },
    {
      render: (
        <AddBuyerStep
          onBack={onBackClick}
          setStepByName={setStepByName}
          removeStepFromPrevSlugs={removeStepFromPrevSlugs}
        />
      ),
      stepName: 'addBuyer',
    },
    {
      render: (
        <AddPeopleToTicketStep
          onBack={onBackClick}
          setStepByName={setStepByName}
          removeStepFromPrevSlugs={removeStepFromPrevSlugs}
        />
      ),
      stepName: 'addPeople',
    },
    {
      render: (
        <AddNewPeopleToTicketStep
          onBack={onBackClick}
          setStepByName={setStepByName}
          openAlert={setOpen}
          openAddPersonAlert={setOpenPersonAdd}
          setSaved={setSaved}
        />
      ),
      stepName: 'addNewPeople',
    },
    {
      render: <ThanksStep setStepByName={setStepByName} saved={saved} />,
      stepName: 'thanks',
    },
    {
      render: (
        <NumberOfPeopleStep
          setStepByName={setStepByName}
          onBack={onBackClick}
        />
      ),
      stepName: 'numberOfPeople',
    },
  ];

  const styles = useStyles();

  return (
    <Grid container={true} justify="center" className={styles.container}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          {SAME_PERSON_IS_ON_LIST_TEXT}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPersonAdded}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" variant="filled">
          {ADDED_PERSON_TEXT}
        </Alert>
      </Snackbar>
      <Grid item={true} xs={12} md={8}>
        {availableSteps[activeStep] && availableSteps[activeStep].render}
      </Grid>
    </Grid>
  );
};

export default HappeningRegister;
