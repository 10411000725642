export const staticCodesSaltos = [
  { name: 'Multisport', value: 'Multisport' },
  { name: 'FitProfit', value: 'FitProfit' },
  {
    name: 'Karta Dużej Rodziny',
    value: 'KDR',
  },
  { name: 'Medicover', value: 'Medicover' },
  { name: 'Karta Łodzianina', value: 'KŁ' },
];
