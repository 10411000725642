import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import { ISubmission } from '@Compo/CustomByClient/Hangar/EntryTerm/EntryTerm.types';
import {
  IPage2Submission,
  ISubmission as INewSubmission,
} from '@Compo/HappeningRegister/RegisterNewPersonModal/EntryTerm/EntryTerm.types';
import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IEntryGroupResponse,
  IFormioSendEmailResponse,
  IFormIoSubmissionsResponse,
  IFormIoUserSubmissionsResponse,
  IFormIoUserWhenExistResponse,
} from './types';

class FormIoApi {
  private static getSubmissionsUrl = () => `${config.api.formioUrl}submissions`;
  private static getSubmissionsUserUrl = () =>
    `${config.api.formioUrl}submissions-user`;
  private static getSubmissionsAttachUrl = () =>
    `${config.api.formioUrl}submissions-attach`;
  private static getFormIoUserWhenExistUrl = () =>
    `${config.api.formioUrl}submissions-user-forms`;
  private static getEntryGroupUrl = () => `${config.api.formioUrl}entry-group`;
  private static getSendEmailUrl = () =>
    `${config.api.formioUrl}form-mail-demand`;
  private static updateUserEntryIdUrl(url: string): string {
    return `${url}/submission`;
  }
  private static checkOldBaseUrl(
    email: string,
    firstname: string,
    lastname: string,
    entryListUrl: string | null,
  ): string {
    if (entryListUrl) {
      return `${entryListUrl}/exists?data.email=${email}&data.firstname=${firstname}&data.lastname=${lastname}`;
    }
    if (config.app.entryListUrl === config.app.oldEntryListUrl) {
      return `${config.app.oldEntryListUrl}/exists?data.email=${email}&data.firstname=${firstname}&data.lastname=${lastname}`;
    }
    return `${config.app.oldEntryListUrl}/exists?data.page1Email=${email}&data.page1Imi=${firstname}&data.page2Enterform.data.Nazwisko=${lastname}`;
  }
  private static getOldDataUrl(
    submissionId: string,
    entryListUrl: string | null,
  ): string {
    if (entryListUrl) {
      return `${entryListUrl}/submission/${submissionId}`;
    }
    return `${config.app.oldEntryListUrl}/submission/${submissionId}`;
  }
  private static getDeleteSubmissionUrl(): string {
    return `${config.api.formioUrl}submissions-detach`;
  }

  private cancelTokenUsers?: CancelTokenSource;

  public saveUserData(
    formUrl: string,
    newUserData: IPage2Submission,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .post(FormIoApi.updateUserEntryIdUrl(formUrl), newUserData, {
          cancelToken: this.cancelTokenUsers.token,
        })
        .then(() => resolve())
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getFormIoSubmissions(
    entryToken: string | null,
    transactionItemHash: string | null,
    formioId: string | null,
  ): Promise<IFormIoSubmissionsResponse> {
    return new Promise<IFormIoSubmissionsResponse>((resolve, reject) => {
      axios
        .get(FormIoApi.getSubmissionsUrl(), {
          params: {
            entryToken,
            formId: formioId || config.app.formIoId,
            transactionItemHash,
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
  public getFormIoUserSubmissions(
    userEmail: string,
    userFirstName: string,
  ): Promise<IFormIoUserSubmissionsResponse> {
    return new Promise<IFormIoUserSubmissionsResponse>((resolve, reject) => {
      axios
        .get(FormIoApi.getSubmissionsUserUrl(), {
          params: {
            formId: config.app.formIoId,
            limit: 2,
            userEmail,
            userFirstName,
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response[0].submission[1].users);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
  public getFormIoUserWhenExist(
    userEmail: string,
    userFirstName: string,
    userLastName: string,
    formioId: string | null,
  ): Promise<IFormIoUserWhenExistResponse> {
    return new Promise<IFormIoUserWhenExistResponse>((resolve, reject) => {
      axios
        .get(FormIoApi.getFormIoUserWhenExistUrl(), {
          params: {
            formId: formioId || config.app.formIoId,
            userEmail,
            userFirstName,
            userLastName,
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public saveSubmissionsAttach(
    submissionId: string,
    entryToken: string | null,
    transactionItemHash: string | null,
    formioId: string | null,
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      axios
        .post(FormIoApi.getSubmissionsAttachUrl(), {
          entryToken,
          formId: formioId || config.app.formIoId,
          submissionId,
          transactionItemHash,
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteSubmission(
    submissionId: string,
    entryToken: string | null,
    transactionItemHash: string | null,
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      axios
        .post(FormIoApi.getDeleteSubmissionUrl(), {
          entryToken,
          submissionId,
          transactionItemHash,
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getEntryGroup(
    quantity: number,
    submissions: string[],
  ): Promise<IEntryGroupResponse> {
    return new Promise<IEntryGroupResponse>((resolve, reject) => {
      axios
        .post(FormIoApi.getEntryGroupUrl(), {
          formId: config.app.formIoId,
          quantity,
          submissions,
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response[0]);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public checkUserExist(
    userEmail: string,
    userFirstName: string,
    userLastName: string,
    entryListUrl: string | null,
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(
          FormIoApi.checkOldBaseUrl(
            userEmail,
            userFirstName,
            userLastName,
            entryListUrl,
          ),
        )
        .then(getData)
        .then((response) => {
          resolve(response._id);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getOldUserData(
    submissionId: string,
    entryListUrl: string | null,
  ): Promise<ISubmission> {
    return new Promise<ISubmission>((resolve, reject) => {
      axios
        .get(FormIoApi.getOldDataUrl(submissionId, entryListUrl))
        .then(getData)
        .then((response) => {
          resolve(
            this.normalizeOldData(
              response,
              entryListUrl
                ? false
                : config.app.entryListUrl !== config.app.oldEntryListUrl,
            ),
          );
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public sendFormEmailPost(
    entryToken: string | null,
    firstName: string,
    lastName: string,
    email: string,
    transactionItemHash: string | null,
    formioId: string | null,
  ): Promise<IFormioSendEmailResponse> {
    return new Promise<IFormioSendEmailResponse>((resolve, reject) => {
      axios
        .post(FormIoApi.getSendEmailUrl(), {
          email,
          entryToken,
          firstName,
          formId: formioId || config.app.formIoId,
          lastName,
          transactionItemHash,
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(catchNewErrorMessage(error));
        });
    });
  }

  public normalizeOldData(
    data: ISubmission,
    hasOldForm: boolean,
  ): INewSubmission {
    const getFirstAndLastName = (user?: IPage2Submission) => {
      if (user) {
        if (hasOldForm) {
          return { firstName: user.data.imie, lastName: user.data.nazwisko };
        }
        return { firstName: user.data.firstname, lastName: user.data.lastname };
      }
      return { firstName: '', lastName: '' };
    };

    const {
      data: {
        page1Email,
        page1EnterFormFormUrl,
        page1Imi,
        page1Kodrezerwacji,
        page1RedirectAfterSubmit,
        page1SmsGateWay,
        page1SmsTekst,
        page2Enterform,
        page3parentfirstname,
        page3parentlastname,
        page3phone,
        page4Numbergen,
        iframe,
        nextPage,
        autoRedirectFormPath,
        userFormUrl,
      },
    } = data;

    const userData = getFirstAndLastName(page2Enterform);

    return {
      data: {
        autoRedirectFormPath,
        email: page1Email,
        entryToken: page1Kodrezerwacji,
        firstname: page1Imi,
        form: {
          data: {
            autoRedirectFormPath,
            iframe,
            nextPage,
            page1Email,
            page1EnterFormFormUrl,
            page1Imi,
            page1Kodrezerwacji,
            page1RedirectAfterSubmit,
            page1SmsGateWay,
            page1SmsTekst,
            page2Enterform,
            page3parentfirstname,
            page3parentlastname,
            page3phone,
            page4Numbergen,
            userFormUrl,
          },
        },
        iframe,
        nextPage: 'page5',
        page1EnterFormFormUrl,
        page1RedirectAfterSubmit,
        page1SmsGateWay,
        page1SmsTekst,
        page2Enterform: page2Enterform
          ? {
              data: {
                ...page2Enterform.data,
                autocomplete: 'true',
                dataurodzenia2: moment(
                  page2Enterform.data.dataurodzenia,
                ).format('YYYY-MM-DD'),
                firstname: userData.firstName,
                lastname: userData.lastName,
              },
            }
          : page2Enterform,
        page3parentfirstname,
        page3parentlastname,
        page3phone,
        page4Numbergen,
        userFormUrl,
      },
    };
  }
}

export { FormIoApi };
export default new FormIoApi();
