import React, { ChangeEvent, useEffect } from 'react';

import { Button, Grid, TextField } from '@material-ui/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { HeaderContentMUI } from '@/srcShared/ui/molecules/HeaderContentMUI';
import { HeaderContentMUIVariant } from '@/srcShared/ui/molecules/HeaderContentMUI/models/HeaderContentMUI';
import config from '@Config';
import { formioData, iframeParams } from '@Recoil/formio/atoms';
import { translation } from '@Recoil/lang/selectors';
import Analytics from '@Services/$analytics-provider';
import useStyles from './TicketCodeStep.styles';
import { ITicketCodeStepProps } from './TicketCodeStep.types';

const TicketCodeStep = ({ onBack, setStepByName }: ITicketCodeStepProps) => {
  const styles = useStyles();
  const setFormioData = useSetRecoilState(formioData);
  const reservationCode = useRecoilValue(formioData).reservationCode;
  const { entryListL } = useRecoilValue(translation);

  const { ticketCode } = useRecoilValue(iframeParams);

  useEffect(() => {
    Analytics.logEvent('page_view', {
      page_title: entryListL.ticketStep.enterTicketCode,
    });
  }, []);

  useEffect(() => {
    setFormioData((oldFormioData) => ({
      ...oldFormioData,
      reservationCode: ticketCode,
    }));
  }, [ticketCode]);

  const handleInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFormioData((oldFormioData) => ({
      ...oldFormioData,
      reservationCode: e.target.value,
    }));
  };

  const spacing = config.spacings;

  return (
    <HeaderContentMUI
      variant={HeaderContentMUIVariant.GENERIC}
      withHeader={true}
      withHeadParagraph={false}
      title={entryListL.ticketStep.enterTicketCode}
      withBottomAppendix={config.app.showFirstStepEntryList && !ticketCode}
      onBottomAppendixClick={onBack}
    >
      <Grid container={true} spacing={spacing.regular} direction="column">
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            value={reservationCode}
            placeholder={entryListL.ticketStep.ticketCode}
            helperText={entryListL.ticketStep.ticketCodeHelper}
            fullWidth={true}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item={true}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Button
                className={styles.button}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => setStepByName('addBuyer')}
              >
                {entryListL.next}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HeaderContentMUI>
  );
};

export default TicketCodeStep;
